<template>
  <div id="app">
    <NavBar />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";


export default {
  name: "app",
  components: {NavBar}

}
</script>

