<template>
  <div class="home contain">
    <div class="container my-12 mx-auto px-4 md:px-12 ">
      <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center">

        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4 ">
          <!-- Article -->
          <router-link to="parcours">
            <article class="overflow-hidden rounded-lg shadow-lg border-b-4 border-gray-500 hover:border-purple-600 transition duration-500">
              <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 class="text-lg">
                    Mon parcours
                </h1>

              </header>
              <footer class="flex items-center justify-between leading-none p-2 md:p-4">

              </footer>

            </article>
          </router-link>
          <!-- END Article -->
        </div>
        <!-- END Column -->

        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4">

          <!-- Article -->
          <router-link to="projet">
            <article class="overflow-hidden rounded-lg shadow-lg border-b-4 border-gray-500 hover:border-purple-600 transition duration-500">
              <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 class="text-lg">
                  Mes projets
                </h1>

              </header>

              <footer class="flex items-center justify-between leading-none p-2 md:p-4">

              </footer>

            </article>
          </router-link>
          <!-- END Article -->

        </div>
        <!-- END Column -->
      </div>

      <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center">
        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4">

          <!-- Article -->
          <router-link to="veille">
            <article class="overflow-hidden rounded-lg shadow-lg border-b-4 border-gray-500 hover:border-purple-600 transition duration-500">
              <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 class="text-lg">
                  Veille technologique
                </h1>

              </header>

              <footer class="flex items-center justify-between leading-none p-2 md:p-4">

              </footer>

            </article>
          </router-link>
          <!-- END Article -->

        </div>
        <!-- END Column -->

        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4">

          <!-- Article -->
          <router-link to="competence">
            <article class="overflow-hidden rounded-lg shadow-lg border-b-4 border-gray-500 hover:border-purple-600 transition duration-500">
              <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 class="text-lg">
                  Mes compétences
                </h1>

              </header>

              <footer class="flex items-center justify-between leading-none p-2 md:p-4">

              </footer>

            </article>
          </router-link>
          <!-- END Article -->

        </div>
        <!-- END Column -->
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>
<style scoped>

/* Global */



.typewriter h1 {

  text-align: justify;
  font-size: 50px;
  font-family: monospace;
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation:
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

</style>