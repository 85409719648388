<template>
  <div class="header-2" >
    <nav class="navBar bg-white py-2 md:py-4 z-20">
      <div class="container px-4 mx-auto md:flex md:items-center">

        <div class="flex justify-between items-center" @click="distribActive">

          <router-link to="/" >
            <div class="font-extrabold text-xl text-black flex">
              <div>
                <img src="../assets/img/logo.png" alt="Logo" class="w-12">
              </div>
              <div class="mt-2 -ml-4 text-gray-900">OUSSAINT CARLOTTI</div>
            </div>

          </router-link>
          <button id="menuBtn" class="hamburger block md:hidden focus:outline-none" type="button" @click="navButton">
            <span class="hamburger__top-bun"></span>
            <span class="hamburger__bottom-bun"></span>
          </button>
        </div>

        <div class="hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
          <router-link v-bind:class="{ active: this.elements[0].value }" to="/" id="/"
                       class="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300">Menu</router-link>
          <router-link v-bind:class="{ active: this.elements[1].value }" to="parcours" id="/parcours"
                       class="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300">Parcours</router-link>
          <router-link v-bind:class="{ active: this.elements[2].value }" to="projet" id="/projet"
                       class="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300">Projets</router-link>
          <router-link v-bind:class="{ active: this.elements[3].value }" to="veille" id="/veille"
                       class="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300">Veille</router-link>
          <router-link v-bind:class="{ active: this.elements[4].value }" to="competence" id="/competence"
                       class="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300">Compétences</router-link>
        </div>
      </div>

    </nav>
    <router-view/>
  </div>

</template>

<script>
export default {
  name: 'NavBar',
  data(){
    return{
      isEditing: false,
      elements:[
        {
          'key': '/',
          'value': false
        },
        {
          'key': '/parcours',
          'value': false
        },
        {
          'key': '/projet',
          'value': false,

        },
        {
          'key': '/veille',
          'value': false,
        },
        {
          'key': '/competence',
          'value': false,
        }

      ]
    }
  },

  watch:{
    $route (){
      this.distribActive();
    }
  },
  mounted() {
    this.distribActive();
  },
  methods:{
    navButton(){
      let collapse = document.getElementById('navbar-collapse');
      let btn = document.getElementById('menuBtn');
      //let nav = document.getElementById('menu');

      collapse.classList.toggle("hidden");
      collapse.classList.toggle("flex");
      btn.classList.toggle('open');
     // nav.classList.toggle('flex');
     // nav.classList.toggle('hidden');

    },
    distribActive(){
      if (document.getElementById(window.location.pathname)){
        this.elements.forEach(element => {
          if (element.key === window.location.pathname){
            element.value = true;
          }else
            element.value = false;
        })
        document.getElementById(window.location.pathname).classList.add("active");
      }

    }
  }

}
</script>
<style>
.navBar{
  position: fixed;
  border-bottom: 1px solid #eaeaea;
  box-shadow: 0px 0px 20px rgb(0, 0, 0);
  width: 100%;
}
.active{
  border-bottom: 3px solid #6B7280;
}

/* HAMBURGER MENU */
.hamburger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: '';
  position: absolute;
  width: 24px;

  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #333;
}

.hamburger__top-bun {
  transform: translateY(-5px);
}

.hamburger__bottom-bun {
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(-1px);
}

.open .hamburger__top-bun {
  transform:
      rotate(45deg)
      translateY(0px);
}

.open .hamburger__bottom-bun {
  transform:
      rotate(-45deg)
      translateY(0px);
}
</style>