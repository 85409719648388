import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/competence',
    name: 'Competence',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Competence')
  },
  {
    path: '/parcours',
    name: 'Parcours',
    component: () => import(/* webpackChunkName: "about" */ '../views/Parcours')
  },
  {
    path: '/projet',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project')
  },
  {
    path: '/veille',
    name: 'Veille',
    component: () => import(/* webpackChunkName: "about" */ '../views/Veille')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
